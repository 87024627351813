import React from "react";
import "./index.css";

const NotFound = () => {
  return (
    <div className="container">
      <img src="https://http.cat/404" alt="404 Not Found" />
    </div>
  );
};

export default NotFound;
